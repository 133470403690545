import { BsArrowLeft } from "react-icons/bs";
import Header from "components/newCommerce/typography/txtHeader";
import OrderDetails from "./orderdetails";
import Description from "components/newCommerce/typography/txtDescription";
import { useEffect, useState } from "react";
import { Orderlist, getProductInfo } from "api/commerce/Apiactions";
import { CircularProgress } from "@mui/material";
import { formatisoDate, FormatwithComma } from "utils/commerceUtils";
const Orderhistory = () => {
  const [history, setHistory] = useState("orderhistories");
  console.log(history);
  const [currentlyCliked, SetClcked] = useState(null);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentdata, setcurrentdata] = useState(null);
  const Slidetodetails = (payload, currentitem) => {
    setHistory("orderdetails");
    SetClcked(payload);
    setcurrentdata(currentitem);
  };

  // fetch-lists-of-rders

  useEffect(() => {
    setLoading(true);
    setError(null);

    const Fetchorders = async () => {
      try {
        const response = await Orderlist();
        // get-more-product-info

        if (response) {
          const productDetails = response.flatMap((order) =>
            order.order_items.map((item) => getProductInfo(item.product))
          );

          const getProductInfoResponses = await Promise.all(productDetails);

          const returnedValues = response.map((order) => {
            const updatedOrderItems = order.order_items.map((item) => {
              const productInfo = getProductInfoResponses.find(
                (info) => info.data.id === item.product
              );

              // calculate-price
              // const calcPrice = productInfo.data.reduce((total, item) => {
              //   const price = Number(item.price) || 0;
              //   const quantity = Number(item.quantity) || 0;
              //   return total + price * quantity;
              // }, 0);
              return {
                ...item,
                product_bg: productInfo?.data.product_image || "",
                product_name: productInfo?.data.name || "",
                calcPrice: productInfo?.data.price,
                // seller-id-toget-product-seller
                productseller: productInfo?.data.user || "",
              };
            });

            return {
              ...order,
              order_items: updatedOrderItems,
            };
          });

          setOrders(returnedValues);
          console.log(returnedValues);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    Fetchorders();
  }, []);

  if (loading) {
    return (
      <div className="text-center pt-3 bg-[#f5f5f5] flex-[7] pb-2 cart_mobile">
        <div className="mt-[15%]">
          <CircularProgress />
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center pt-3 bg-[#f5f5f5] flex-[7] pb-2 cart_mobile">
        <div className="pt-[15%]">
          <h2>Error getting Orders list</h2>
        </div>
      </div>
    );
  }
  return (
    <div className="flex-[7] pt-2 pb-3 bg-[#f5f5f5] cart_mobile">
      {history === "orderhistories" ? (
        <>
          <div className="flex flex-row items-center justify-between ">
            <div className="flex flex-row items-center gap-3">
              <BsArrowLeft
                fontSize="20px"
                onClick={() => window.history.back()}
              />
              <Header title="Order History" />
            </div>
          </div>

          {/* all-order-history */}

          <div className="mt-4 cart_item_mobile">
            <div className="flex flex-col gap-y-[0rem]">
              {orders.length < 1 ? (
                <>
                  <h3 className="text-center">No order Yet</h3>
                </>
              ) : (
                orders.map((ordered, index) => {
                  return (
                    <div
                      className="px-4 flex justify-between flex-wrap rounded-xl gap-y-[0]"
                      key={index}
                    >
                      <div className="py-3 flex justify-between flex-wrap rounded-xl gap-y-[1.3rem] w-full">
                        {ordered.order_items.map((actualorder) => {
                          return (
                            <div
                              className=" bg-[#ffff] py-3 px-4 flex justify-between w-full  rounded-xl gap-y-[1.3rem]"
                              key={index}
                            >
                              {/* first-content-also-flexing */}
                              <div className="flex gap-x-6 items-center">
                                <div className="">
                                  <img
                                    className="w-[70px] h-[60px] rounded-[md]"
                                    src={actualorder?.product_bg || ""}
                                    alt="ordered-product"
                                  />
                                </div>
                                {/* sub_details */}
                                <div className="flex flex-col justify-between gap-y-[0.45rem]  ">
                                  <Description
                                    title={`Order Date: ${formatisoDate(
                                      ordered.created_at
                                    )}`}
                                  />
                                  <Header
                                    title={`${actualorder.product_name}`}
                                    fw="400"
                                    cl="#000000"
                                  />
                                  <div className="flex gap-x-1 text-center">
                                    <Header
                                      title={`Total:`}
                                      fw="400"
                                      cl="#000000"
                                    />
                                    <Description
                                      title={FormatwithComma(
                                        actualorder.calcPrice
                                      )
                                        .toString()
                                        .replace(/^/, "₦")}
                                      fs={"15px"}
                                    />
                                  </div>
                                  <div
                                    className={`text-[12px] font-[400]  rounded-[15px] text-[#ffff] w-max p-2 `}
                                    style={{
                                      background: ordered.status
                                        ? "#207700"
                                        : "#4F0DA3",
                                      // : "rgba(34, 34, 34, 0.7)",
                                    }}
                                  >
                                    {ordered.status
                                      ? "Delivered"
                                      : "Delivery in progress"}
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col gap-y-4 items-end">
                                <p
                                  className="py-[3px] px-[6px] text-[12px] text-center w-max cursor-pointer"
                                  style={{
                                    background: "rgba(79, 13, 163, 0.2)",
                                    color: "rgba(79, 13, 163, 1)",
                                  }}
                                  onClick={() =>
                                    Slidetodetails(ordered, actualorder)
                                  }
                                >
                                  View details
                                </p>
                                <div className="flex gap-x-1 items-center">
                                  <Header
                                    title={`Order No:`}
                                    fw="400"
                                    cl="#000000"
                                  />
                                  <Description
                                    title={ordered.id
                                      .toString()
                                      .replace(/^/, "#")}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </>
      ) : history === "orderdetails" ? (
        <>
          <OrderDetails
            payload={currentlyCliked}
            reverse={setHistory}
            currencheck={currentdata}
          />
        </>
      ) : null}
    </div>
  );
};
export default Orderhistory;
