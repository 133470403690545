/* eslint-disable eqeqeq */
import useReadChat from "Hooks/chat/useReadChat";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";

const SinglePublicChat = ({ chat, userID }) => {
  const pageRef = useRef(null);
  const [hasRead, setHasRead] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const formatDate = (date) => {
    const now = moment();
    const inputDate = moment(date);

    const isToday = inputDate.isSame(now, "day");

    const formattedDate = isToday
      ? inputDate.format("hh:mm A")
      : inputDate.format("DD MMM");

    return formattedDate;
  };

  const { readChat } = useReadChat();

  const truncateText = (text) => {
    const words = text?.split(' ');
    if (words.length <= 35 || isExpanded) return text;
    return words.slice(0, 35).join(' ') + '...';
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleReadChat = useCallback(() => {
    if (chat?.sender != userID && !hasRead) {
      readChat({ message_id: chat?.id });
      setHasRead(true);
    }
  }, [chat, userID, readChat, hasRead]);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    handleGetRef();
  }, [chat]);

  useEffect(() => {
    if (!pageRef.current || hasRead) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleReadChat();
          observer.disconnect();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(pageRef.current);

    return () => {
      observer.disconnect();
    };
  }, [handleReadChat, hasRead]);

  return (
    <div
      key={chat?.id}
      className={`flex gap-4 bg-[#ecebeb] ${
        chat?.sender == userID ? "justify-end" : "justify-start"
      }`}
      onClick={handleReadChat}
    >
      <div
        className={`px-3 py-1 text-[16px] mt-8 w-fit max-w-[75%] shadow-sm ${
          chat?.sender == userID
            ? "bg-[#4f0da3] rounded-tl-lg rounded-tr-none rounded-br-lg rounded-bl-lg"
            : "bg-white rounded-tl-none rounded-tr-lg rounded-br-lg rounded-bl-lg"
        }`}
      >
        <p
          style={{ margin: 0 }}
          className={`lg:text-[14px] ${
            chat?.sender == userID ? "text-white" : "text-black"
          }`}
          ref={pageRef}
        >
          {truncateText(chat?.text)}{" "}
          {chat?.text.split(' ').length > 35 && (
            <span className="text-blue-500 cursor-pointer" onClick={handleToggle}>
              {isExpanded ? "Show less" : "Show more"}
            </span>
          )}
        </p>
        <p
          style={{ margin: 0 }}
          className={`text-[9px] ml-6 text-right text-neutral-300 ${
            chat?.sender == userID ? "text-neutral-200" : "text-neutral-500"
          }`}
        >
          {formatDate(chat?.created_at)}
        </p>
      </div>
    </div>
  );
};

export default SinglePublicChat;
