/* eslint-disable eqeqeq */
import { useGetAllAccounts } from "Hooks/profile/useGetAllAccounts";
import { useGetPublicProfile } from "Hooks/profile/useGetPublicProfile";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { TbDotsVertical } from "react-icons/tb";
import ads from "../../assets/profile_images/Jumia-Celebrate-the-Game.png";
import { useNavigate, useParams } from "react-router-dom";
import { noUser } from "utils/noUser";
import InnerChat from "./InnerChat";
import { CiFaceSmile } from "react-icons/ci";
import { BsSend } from "react-icons/bs";
import { useProfile } from "Hooks/profile/useProfile";
import useSendDirectMessage from "Hooks/chat/useSendDirectMessage";
import { useGetConversation } from "Hooks/chat/useGetConversation";
import { usePublicMessages } from "Hooks/chat/usePublicMessages";
import InnerPublicChat from "./InnerPublicChat";
import useSetChatMode from "Hooks/chat/useSetChatMode";
import nom from "../../assets/chat_images/nom.png";

const DirectPublicChat = () => {
  const { id } = useParams();
  const { allAccounts, isAllAccountsLoading, allAccountsError } =
    useGetAllAccounts();

  const { profileData, isProfileDataLoading, profileDataError } = useProfile();

  const { publicMessages, isPublicMessagesLoading, publicMessagesError } =
    usePublicMessages();

  const { sendDirectMessage, sendDirectMessageStatus } = useSendDirectMessage();

  const { handlePublicChat } = useSetChatMode();
  const allPublicMessages = publicMessages?.data;

  const publicMessage = allPublicMessages?.filter((item) => item.sender == id);

  const user = allAccounts?.data?.data?.find(
    // eslint-disable-next-line eqeqeq
    (item) => item?.user?.id == id
  );

  const myID = profileData?.data?.data?.user?.id;

  const isUser = myID == id;

  const [text, setText] = useState("");

  const goBack = () => {
    navigate("/chat");
    handlePublicChat();
  };

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ bottom: 0 });
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const formData = {
      recipient_id: id,
      text,
      message_type: "public_messages",
    };
    sendDirectMessage(formData);
    setText("");
    //setIsPromoteModalOpen(true)
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const isActive = false;

  const navigate = useNavigate();
  return (
    <div className="bg-neutral-100 px-3 py-3">
      <div
        className={`flex items-center p-2 shadow-sm bg-white h-[60px]
        ${isUser ? "gap-0" : "gap-3"}
        `}
      >
        <FaArrowLeft
          className="text-[16px] ml-2 cursor-pointer"
          onClick={goBack}
        />
        <div className=" flex items-center justify-between mt-2 w-full">
          <div
            className={` py-3 flex items-center gap-0
            ${isUser ? "ml-0" : "gap-3 ml-4"}
            
            `}
          >
            <div className=" h-[37px] rounded-full flex items-center justify-center w-[37px]">
              {!isUser && (
                <img
                  src={user?.profile_picture ? user?.profile_picture : noUser}
                  alt="user"
                  className="w-[100%] rounded-full h-[100%] object-cover"
                />
              )}
            </div>

            {!isUser ? (
              <div className="">
                {!user?.user?.first_name || !user?.user?.last_name ? (
                  <span className="text-[14px] text-neutral-600 font-semibold">
                    {user?.user?.username}
                  </span>
                ) : (
                  <span className="text-[14px] text-neutral-600 font-semibold">
                    {user?.user?.first_name} {user?.user?.last_name}
                  </span>
                )}

                {/* <div className="text-neutral-500 flex items-center gap-2 font-normal text-[13px]">
                <div className="bg-green-500 h-2 w-2 rounded-full mb-2"></div>
                <h3 className="font-light text-[11px]">Active now</h3>
              </div> */}
              </div>
            ) : (
              <span className="text-[14px] text-neutral-600 font-semibold">
                Your public messages
              </span>
            )}
          </div>
          <div className="">
            <TbDotsVertical className="text-[16px]" />
          </div>
        </div>
      </div>
      {/* <div className="">
        <p className="text-center text-purple-600">Yesterday</p>
      </div> */}
      {publicMessage?.length ? (
        <InnerPublicChat userID={myID} ID={id} data={publicMessage} />
      ) : (
        <div
          style={{ border: "1px solid #d1d5db" }}
          className="p-4 bg-[#f3ebf0] rounded flex flex-col mx-auto mt-[80px] mb-[80px] text-center items-center w-[280px] h-[240px] shadow-sm"
        >
          <div>
            <img src={nom} className="w-[140px]" alt="chathead" />
          </div>
          <div className="mt-2">
            <h3 className="text-center lg:text-[13px] text-[12px] font-semibold">
              Send a message to everyone on 2geda
            </h3>
            <h3 className="text-center lg:text-[12px] text-[12px] font-normal">
              Reach all app users directly with a broadcast message. Start
              typing now
            </h3>
          </div>
        </div>
      )}

      {isUser && (
        <div className=" row-span-1  w-[100%] bottom-0 border shadow-sm z-20">
          <form
            className="w-full border-none bg-neutral-100 rounded flex items-center py-2 px-4"
            onSubmit={handleSendMessage}
          >
            <CiFaceSmile className="text-black text-[24px]" />
            <input
              type="text"
              className="text-[14px] border-none outline-none"
              placeholder="Send a broadcast message"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <button
              className="bg-rose-500 h-12 w-12 p-2 rounded-full flex justify-center items-center"
              type="submit"
            >
              <BsSend className="text-[18px] text-white" />
            </button>
          </form>
        </div>
      )}
      {/* Banner ad */}
      <div className="hidden mt-2 lg:block">
        <img src={ads} alt="Ads" className="w-full h-full object-contain" />
      </div>
    </div>
  );
};

export default DirectPublicChat;
