/* eslint-disable eqeqeq */
import { useGetAllAccounts } from "Hooks/profile/useGetAllAccounts";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { noUser } from "utils/noUser";

const filterUserID = (users, id) => {
  const user = users.find((user) => user.id != id);
  return user ? user.id : null;
};

const sliceName = (text) => {
  if (text?.length > 13) {
    return text.slice(0, 13) + "...";
  } else {
    return text;
  }
};
const sliceText = (text) => {
  if (text?.length > 25) {
    return text.slice(0, 25) + "...";
  } else {
    return text;
  }
};

const sliceTextRightSide = (text) => {
  if (text?.length > 14) {
    return text.slice(0, 14) + "...";
  } else {
    return text;
  }
};

function countFilteredMessages(messages, senderId) {
  let count = 0;

  messages?.forEach(message => {
    if (message.read === false && message.sender !== senderId) {
      count++;
    }
  });

  return count;
}


const TextMessage = ({ text, date, userID, friend, isRead, rightSide, messages }) => {
  const formatDate = (date) => {
    const now = moment();
    const inputDate = moment(date);
    
    const isToday = inputDate.isSame(now, "day");

    const formattedDate = isToday
      ? inputDate.format("hh:mm A")
      : inputDate.format("DD MMM");

    return formattedDate;
  };

  const currentTime = formatDate(date);
  
  const filteredUserID = filterUserID(friend, userID);
  
  const { allAccounts, isAllAccountsLoading, allAccountsError } =
  useGetAllAccounts();
  
  const user = allAccounts?.data?.data?.find(
    (item) => item?.user?.id == filteredUserID
  );
  
  const unread = countFilteredMessages(messages, userID)

  const userFullName = `${user?.user?.first_name} ${user?.user?.last_name}`;

  return (
    <Link to={`/chat/message/${filteredUserID}`} className="no-underline">
      <div>
        <div
          style={{
            borderBottom: "1px solid #f3f3f5",
          }}
          className=" flex items-center justify-between mt-2"
        >
          <div className="flex items-center gap-3 py-3">
            <div
              className={`h-[35px] rounded-full flex items-center justify-center w-[35px]
              ${rightSide ? "h-[40px] w-[40px]" : "h-[35] w-[35]"}
              `}
            >
              <img
                src={user?.profile_picture ? user?.profile_picture : noUser}
                alt="user"
                className="w-[100%] rounded-full h-[100%] object-cover"
              />
            </div>

            <div className="">
              {!user?.user?.first_name || !user?.user?.last_name ? (
                <span
                  className={` text-neutral-600 font-semibold
                ${rightSide ? "text-[13px]" : "text-[14px]"}
                `}
                >
                  {rightSide
                    ? sliceName(user?.user?.username)
                    : user?.user?.username}
                </span>
              ) : (
                <span
                  className={`text-[14px] text-neutral-600 font-semibold
                  ${rightSide ? "text-[13px]" : "text-[14px]"}
                `}
                >
                  {rightSide ? sliceName(userFullName) : userFullName}
                </span>
              )}

              <h3
                className={`text-neutral-400 font-normal
                ${rightSide ? "text-[12px]" : "text-[13px]"}
                `}
              >
                {rightSide ? sliceTextRightSide(text) : sliceText(text)}
              </h3>
            </div>
          </div>
          <div className="flex justify-end flex-col items-end">
            <h3
              className={`text-[#8e70b6] font-normal  flex gap-2 items-center
              ${rightSide ? "text-[10px]" : "text-[11px]"}
              `}
            >
              {currentTime}
            </h3>
            {unread > 0 && (
              <div className="py-2 px-2 bg-[#4f0da3] text-white text-[11px] rounded flex items-center justify-center h-8 ">
                {unread}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TextMessage;
