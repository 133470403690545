import { useFetchLocation } from 'api/services/feeds'
import React from 'react'
import { IoLocationOutline } from 'react-icons/io5'
import { Link } from '@nextui-org/react'

const FeedLocations = () => {
  const { data: getlocation, isPending } = useFetchLocation()
  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      {getlocation?.map((item) => (
        <div
          key={item}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.2rem',
            marginBottom: '.6rem',
          }}
        >
          {/* <Link isExternal href={item?.location}> */}
          <Link isExternal href={`https://maps.google.com/?q=${item.location}`}>
            <IoLocationOutline size={24} />
            <p>{item?.location}</p>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default FeedLocations
