import { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { BiSolidHome } from 'react-icons/bi'
import { BsPersonCircle, BsTicketFill } from 'react-icons/bs'
import { IoChatbubbleEllipsesOutline, IoLogOutOutline } from 'react-icons/io5'
import {
  MdOutlineHowToVote,
  MdOutlineStorefront,
  MdOutlineOndemandVideo,
} from 'react-icons/md'
import { ModalContext } from 'Context/ModalContext'
import toast from 'react-hot-toast'
import connectwhitelogo from '../assets/connectwhitelogo.svg'
import { LiaConnectdevelop } from 'react-icons/lia'
import { IoMdBook } from 'react-icons/io'
import { TiBusinessCard } from 'react-icons/ti'
import { IoMusicalNotesSharp } from 'react-icons/io5'
import { useQueryClient } from '@tanstack/react-query'
import { FaHotel } from 'react-icons/fa'
import { PiTelevisionSimpleBold } from 'react-icons/pi'

import { useProfile } from 'Hooks/profile/useProfile'
import useSetChatMode from 'Hooks/chat/useSetChatMode'

const nav_item_style = {
  active:
    'flex space-x-4 text-[14px] font-medium items-center no-underline text-[#fff]',
  non_active:
    'flex space-x-4 text-[14px] font-light items-center no-underline text-[#c47efb]',
}

export const SliderContent = ({ setSlider }) => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { setIsAuthenticated } = useContext(ModalContext)

  const { profileData } = useProfile()
  const username = profileData?.data?.data?.user?.username

  const {handlePrivateChat} =
    useSetChatMode();

  const handleLogout = () => {
    localStorage.removeItem('authToken')
    localStorage.removeItem('2gedaUserInfo')
    queryClient.removeQueries()
    setIsAuthenticated(false)
    handlePrivateChat()
    nav('/')
    toast.success('Successfully Logged Out')

    // setting slider to false if true
    //setSlider(false);
  }

  return (
    <>
      <div className='flex flex-col space-y-10'>
        <NavLink
          to='/home'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <img src={connectwhitelogo} alt='' />
        </NavLink>

        <NavLink
          to='/home'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <BiSolidHome className='text-[23px]' />
          <div>Home</div>
        </NavLink>
        <NavLink
          to={`/${username}`}
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <BsPersonCircle className='text-[23px]' />
          <div>Profile</div>
        </NavLink>

        <NavLink
          to='/connect'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <LiaConnectdevelop className='text-[23px]' />
          <div>Connect</div>
        </NavLink>

        {/* <NavLink
          to='/chat'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <IoChatbubbleEllipsesOutline className='text-[23px]' />

          <div>Chats</div>
        </NavLink> */}

        {/* <NavLink
          to='/commerce'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <MdOutlineStorefront className='text-[23px]' />
          <div>Commerce</div>
        </NavLink> */}

        <NavLink
          to='/ticket'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <BsTicketFill className='text-[23px]' />
          <div>Tickets</div>
        </NavLink>

        <NavLink
          to='/Voting'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <MdOutlineHowToVote className='text-[23px]' />
          <div>Voting</div>
        </NavLink>
        {/* <NavLink
          to='/live'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <MdOutlineOndemandVideo className='text-[23px]' />
          <div>Live</div>
        </NavLink> */}
        {/* <NavLink
          to='/tv'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <PiTelevisionSimpleBold className='text-[23px]' />
          <div>Tv</div>
        </NavLink> */}

        {/* <NavLink
          to='/stereo'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <IoMusicalNotesSharp className='text-[23px]' />
          <div>Stereo</div>
        </NavLink> */}

        {/* <NavLink
          to='/education'
          className={({ isActive }) =>
            isActive ? 'active_link side-item' : 'side-item'
          }
        >
          <IoMdBook className='side-icon ' />
          <div className='sidebar-text'>Education</div>
        </NavLink> */}

        {/* <NavLink
          to='/business'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <TiBusinessCard className='text-[23px]' />
          <div>Business Directory</div>
        </NavLink> */}

        {/* <NavLink
          to='/hotels'
          className={({ isActive }) =>
            isActive ? nav_item_style['active'] : nav_item_style['non_active']
          }
        >
          <FaHotel className='text-[23px]' />
          <div>Hotels</div>
        </NavLink> */}
{/* https://2geda.net/ticket/event/33ea298 */}
        
      </div>

      <button
        className='flex items-center space-x-2 text-[15px] text-white'
        onClick={handleLogout}
      >
        <IoLogOutOutline className='text-[23px]' />
        <span>Sign out</span>
      </button>
    </>
  )
}
