import { useFetchVideo } from 'api/services/feeds'
import React from 'react'
import EmptyImage from '../../../../assets/2gede_nothing_here.svg'


const Feedvideos = () => {
  const { data: fetchVideo, isPending } = useFetchVideo()
  const fileBaseUrl = `https://2geda-backend.s3.amazonaws.com`

  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        gap: '1rem',
      }}
    >
      <div className='pb-8'>
        {fetchVideo?.length === 0 && (
          <div className='text-3xl'>
            <h1>No Video at the moment, please come back later</h1>
            <img src={EmptyImage} alt='nothing here' />
          </div>
        )}
      </div>
      {fetchVideo?.map((item) => (
        <div className='' key={item?.file_id}>
          {/* <iframe
            width='100%'
            height='120'
            src={fileBaseUrl + item?.file}
            title='Youtube Player'
            // frameborder='0'
            frameBorder='0'
            allowFullScreen
          /> */}
          <div key={item?.file_id}>
            <video width='100%' height='120' controls>
              <source src={"https://i.2geda.net" + item?.file} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Feedvideos
