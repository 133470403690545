import { useChatStore } from "../../zustand/store";

const useSetChatMode = () => {
  const chatMode = useChatStore((state) => state.chatMode);
  const handlePrivateChat = useChatStore((state) => state.handlePrivateChat);
  const handlePublicChat = useChatStore((state) => state.handlePublicChat);
  const handleActiveChat = useChatStore((state) => state.handleActiveChat);
  
  return {
    chatMode,
    handlePrivateChat,
    handlePublicChat,
    handleActiveChat
  };
};

export default useSetChatMode;
