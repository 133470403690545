/* eslint-disable eqeqeq */
import useReadChat from "Hooks/chat/useReadChat";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import SingleChat from "./SingleChat";
import SinglePublicChat from "./SinglePublicChat";
//import { CiFaceSmile } from "react-icons/ci";


const InnerPublicChat = ({ data, userID, ID }) => {
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView();
    }
  };


  useEffect(() => {
    handleGetRef();
  }, [data]);

  return (
    <div className="">
      <div className="p-4 h-[400px] overflow-y-auto [overflow-anchor:auto] bg-[#ecebeb]">
        {data?.length
          ? data
              ?.slice()
              .reverse()
              .map((chat) => (
                <SinglePublicChat key={chat?.id} userID={userID} chat={chat} />
              ))
          : null}
      </div>
    </div>
  );
};

export default InnerPublicChat;
